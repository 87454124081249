import React, { createRef } from "react";
import { Text, IconForwardSlash } from "@fundrecs/ui-library";
import { tbAppChannel } from "../utils/communication/AppChannels";
import CommandArgsModel from "../utils/domain/CommandArgsModel";
import styles from "./AIAssistantInput.module.scss";

export default class AIAssistantInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: "", showDropdown: false };
    this.dropdownRef = createRef();
    this.inputRef = createRef();
    this.options = this?.props?.commandResultArray[0].fundsData.headers;
  }

  handleChange = (event, valName) => {
    let params = this?.props?.commandParams?.userInputargs || {};
    const value = event?.target?.value;
    params[valName] = value;

    this.setState({ inputValue: value, showDropdown: value.includes("/") }, () => {
      this.inputRef.current.focus();
      tbAppChannel.publish(
        "onCommandParamsChange",
        new CommandArgsModel({
          uiCommand: this.props.commandParams.uiCommand,
          userInputargs: params,
          isNew: this.props.commandParams.isNew,
        })
      );
    });
  };

  handleOptionClick = (option) => {
    // Set the state of the component with the new input value and hide the dropdown
    this.setState(
      (prevState) => ({
        // Replace the current inputValue by appending the selected option followed by a space
        inputValue: prevState.inputValue.replace(/\/?$/, option + " "),
        // Hide the dropdown after an option is selected
        showDropdown: false,
      }),
      // Callback function executed after the state has been updated
      () => {
        // Focus the input field to allow the user to continue typing
        this.inputRef.current.focus();
      }
    );
  };

  handleClickOutside = ({ target }) => {
    // Check if the click is outside the dropdown and the input field
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(target) && // If the click is outside the dropdown
      this.inputRef.current !== target
    ) {
      // And the click is not on the input field
      // Update the state to hide the dropdown
      this.setState({ showDropdown: false });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const { showDropdown, inputValue } = this.state;
    const { peName } = this.props;

    return (
      <div className="position-relative w-100">
        <Text size="md" weight="regular" element="div">
          How can I help you transform your data?
        </Text>

        {/* Wrapped input & dropdown inside a relative container */}
        <div className="position-relative w-100">
          <input ref={this.inputRef} type="text" value={inputValue} onChange={(event) => this.handleChange(event, peName)} className="form-control" />

          {/* Dropdown stays inside the same container */}
          {showDropdown && (
            <div ref={this.dropdownRef} className={["position-absolute bg-white border rounded shadow overflow-auto", styles.aiDropdown].join(" ")}>
              {this?.options?.map((option, index) => (
                <div key={index} title={option} className=" dropdown-item text-truncate" onClick={() => this.handleOptionClick(option)}>
                  <Text size="sm" weight="regular" classes="pt-2" element="div">
                    {option}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>

        <Text size="sm" weight="regular" classes="pt-12" element="div">
          Type <IconForwardSlash /> to insert column
        </Text>
      </div>
    );
  }
}
