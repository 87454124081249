import { makeAutoObservable } from "mobx";
import ReactDOM from "react-dom";
import TBAPP from "../TemplateBuilder/components/TbApp";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const templateBuilderApi = fusionDataApi.tbUi;

class TbStore {
  templateUuid = "";
  teamId = null;
  errorModal = false;
  doneButtonDisabled = true;
  doneButtonOnClick = {};
  doneButtonRender = true;
  allRequiredMapped = true;
  templateSampleFile = [];
  outputMappingUuid = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = (templateUuid, teamId) => {
    this.templateUuid = templateUuid;
    this.teamId = teamId;
  };

  getTemplateUuid = () => {
    return this.templateUuid;
  };

  getTeamId = () => {
    return this.teamId;
  };

  setErrorModal = (value) => {
    this.errorModal = value;
  };

  getErrorModal = () => {
    return this.errorModal;
  };

  setDoneButtonOnClick = (onComplete) => {
    this.doneButtonOnClick = onComplete;
  };

  remountTemplateBuilder = (mountData) => {
    this.unmountTemplateBuilder();
    this.mountTemplateBuilder(mountData);
  };

  allRequiredMappedChange = (value) => {
    this.allRequiredMapped = value;
  };
  templateBuilderDoneButtonDisabled = (value) => {
    this.doneButtonDisabled = value;
  };

  unmountTemplateBuilder = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("TemplateBuilderApp"));
  };

  mountTemplateBuilder = (showDoneButton = true) => {
    this.doneButtonRender = showDoneButton;

    ReactDOM.render(
      <TBAPP module={"fusion"} templateUuid={this.getTemplateUuid()} step={"-1"} teamId={this.getTeamId()} onComplete={this.doneButtonOnClick} />,
      document.getElementById("TemplateBuilderApp")
    );
  };

  showErrorModal = () => {
    this.setErrorModal(true);
    this.unmountTemplateBuilder();
  };

  mountErrorModal = (errorModal) => {
    ReactDOM.render(errorModal, document.getElementById("TemplateBuilderApp"));
  };

  reloadTemplateBuilder = ({ teamId }) => {
    this.setErrorModal(false);
    this.unmountTemplateBuilder();
    this.mountTemplateBuilder();
  };

  createNewTemplate = async (request) => {
    const { name, teamId } = request;

    // File upload requires formData and custom headers
    const formData = new FormData();
    for (const [key, value] of Object.entries(request)) {
      formData.append(key, value);
    }

    return templateBuilderApi
      .createNewTemplate(teamId, formData)
      .then((response) => {
        const { status } = response;

        if (status === 200 || status === 201) {
          const {
            data: { templateUuid },
          } = response;

          this.templateUuid = templateUuid;
          this.teamId = teamId;
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add template "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to add template "${name}" ! Please try again`);
        return { success: false };
      });
  };

  async getRequiredTemplate(data) {
    return templateBuilderApi
      .getRequiredTemplate(data)
      .then((response) => {
        return { ...response, ...{ success: true } };
      })
      .catch((error) => {});
  }

  getResultAndStack = (data) => {
    return templateBuilderApi
      .getResultAndStack(data)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          return { ...response, success: true };
        } else {
          return { ...response, success: false };
        }
      })
      .catch(() => {
        return { success: false };
      });
  };

  updateTemplateType = async (data) => {
    try {
      const response = await templateBuilderApi.updateTemplateType(data);
      const { status } = response;

      if (status === 200) {
        uiStore.addNotification("success", "Output mapping successfully saved");

        return { ...response, success: true };
      } else {
        uiStore.addNotification("error", "Unable to apply output mapping! Please try again");

        return { ...response, success: false };
      }
    } catch (error) {
      uiStore.addNotification("error", "Unable to apply output mapping! Please try again");

      console.error("Error updating template type:", error.message);
    }
  };
}
export { TbStore };
