import React from "react";
import { tbAppChannel } from "./utils/communication/AppChannels";
import HttpMediator from "./utils/http/HttpMediator";
import { appSingleton } from "./utils/AppSingleton";
import TypeChanger from "./TypeChanger";
import Spinner from "./Spinner";
import { checkAllRequired } from "../../utils/authorization";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FormatClearIcon from "@material-ui/icons/FormatClear";
import { AUTHORITIES } from "utils/enums";
import { DialogContent, DialogActions, DialogContentText, Button, Dialog, IconButton, Grid } from "@material-ui/core";
import FileChanger from "./fileuploader/FileChanger";

import { meStore } from "../../store/Store";

export default class TbToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTypeDialog: false,
      showDeleteDialog: false,
      showFileDialog: false,
    };
    this.toggleTemplateTypeModal = this.toggleTemplateTypeModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleFileModal = this.toggleFileModal.bind(this);
    this.changeTemplateType = this.changeTemplateType.bind(this);
  }

  toggleTemplateTypeModal() {
    this.setState({ showTypeDialog: !this.state.showTypeDialog });
  }

  toggleDeleteModal() {
    this.setState({ showDeleteDialog: !this.state.showDeleteDialog });
  }

  toggleFileModal() {
    this.setState({ showFileDialog: !this.state.showFileDialog });
  }

  deleteTemplate() {
    HttpMediator.deleteTemplate().then((jsonResponse) => {
      window.location.reload(false);
    });
  }

  //changeTemplateType changes the outputMapping not the template
  async changeTemplateType(typeId) {
    const { success } = await HttpMediator.updateTemplateType(typeId);

    if (success) {
      this.toggleTemplateTypeModal();
    }
  }

  render() {
    //Colour of view toggle buttons depends on currently selected view
    let commandStackButtonColor = this.props.applyMappings ? "#edece7" : "#fafafa";
    let mappingsViewButtonColor = this.props.applyMappings ? "#fafafa" : "#edece7";
    let templateTitle = "Template name: " + appSingleton.templateName + ", Template type: " + appSingleton.templateType;

    return (
      <div>
        <Grid id="tb-toolbar" className="margin-top-6" container direction="row" justifyContent="space-between">
          <Grid item>
            <IconButton
              title="Template Rules"
              disabled={!this.props.applyMappings}
              className="toolbar-nav-buttons"
              style={{
                backgroundColor: commandStackButtonColor,
              }}
              onClick={() => {
                tbAppChannel.publish("hideMappings", {});
              }}
            >
              <FormatListNumberedIcon />
            </IconButton>
            <IconButton
              title="Template Mappings"
              disabled={this.props.applyMappings}
              className="toolbar-nav-buttons"
              style={{
                backgroundColor: mappingsViewButtonColor,
              }}
              onClick={() => {
                tbAppChannel.publish("showMappings", {});
              }}
            >
              <CompareArrowsIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Spinner />
          </Grid>
          <Grid item>
            {checkAllRequired(meStore.getMe(), meStore.getAllActions(), [AUTHORITIES.TEMPLATE_EDIT]) && (
              <IconButton title="Change Sample File" onClick={this.toggleFileModal}>
                <AttachFileIcon />
              </IconButton>
            )}
            {checkAllRequired(meStore.getMe(), meStore.getAllActions(), [AUTHORITIES.TEMPLATE_CHANGE_TYPE]) && (
              <IconButton title="Change template type" disabled={!appSingleton.approvalOptions.changeType.enable} onClick={this.toggleTemplateTypeModal}>
                <FormatClearIcon />
              </IconButton>
            )}
            <TypeChanger
              typeOptions={appSingleton.templateTypes}
              onSelectType={this.changeTemplateType}
              show={this.state.showTypeDialog}
              onClose={this.toggleTemplateTypeModal}
            />
            <FileChanger show={this.state.showFileDialog} onClose={this.toggleFileModal} />
          </Grid>
        </Grid>
        <p className="margin-left-10 font-medium margin-right-10 font-500 cellWithEllipsis max-width-100-pc" title={templateTitle}>
          {appSingleton.templateName}
        </p>
        <p className="margin-left-10 font-small margin-bottom-0">{appSingleton.templateType}</p>
        <Dialog open={this.state.showDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Delete this template?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteTemplate} autoFocus>
              Delete
            </Button>
            <Button onClick={this.toggleDeleteModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
