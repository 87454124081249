import { Grid, Fab, IconButton, Paper, Typography, ListItemText } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import React from "react";
import { appSingleton } from "../utils/AppSingleton";
import AppUtils from "../utils/AppUtils";
import { tbAppChannel } from "../utils/communication/AppChannels";
import IndexedValue from "../utils/domain/selectandtransform/IndexedValue";
import HttpMediator from "../utils/http/HttpMediator";
import CommandParamInputs from "./CommandParamInputs";
import "./commandparams.css";
import { LumsCommand } from "./lumsCommand/LumsCommand";
import ParseFileComponent from "./ParseFileComponent";

export default class CommandParams extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setState({
      steps: {},
      currentStep: 0,
      multiSelectAllowed: false,
      multiSelectionEnabled: false,
    });
  }

  render() {
    if (this.props.commandParams.uiCommand == undefined) {
      return null;
    }
    if (this.props.commandParams.uiCommand.name.peName === "parseFile") {
      return <ParseFileComponent commandParams={this.props.commandParams} />;
    } else if (this.props.commandParams.uiCommand.name.peName === "lumsCommand") {
      return (
        <LumsCommand
          mode={this.props.mode}
          commandParams={this.props.commandParams}
          commandResultArray={this.props.commandResultArray}
          insertRule={this.insertRule.bind(this)}
          updateRule={this.updateRule.bind(this)}
        />
      );
    }

    let CommandParamsRules = () => {
      if (this.validateArguments()) {
        if (this.props.commandParams.isNew) {
          return (
            <Fab className="submitParamsButton paramsButton" title="Add rule" onClick={this.insertRule.bind(this)} size="small">
              <DoneIcon />
            </Fab>
          );
        } else {
          if (this.props.commandParams.uiCommand.argsSpec.length > 0 && !appSingleton.readOnly) {
            return (
              <Fab className="submitParamsButton paramsButton" title="Update rule" onClick={this.updateRule.bind(this)} size="small">
                <DoneIcon />
              </Fab>
            );
          }
        }
      }
    };

    let multiButtonColour = this.state.multiSelectionEnabled ? "#00CC00" : "#0000008a";

    return (
      <Paper className="command-params-root">
        <Grid container spacing={0}>
          <Grid item sm={1}></Grid>
          <Grid item sm={8}>
            <Typography variant="subtitle1" title={this.props.commandParams.uiCommand.name.uiDesc} className="command-params-title">
              {AppUtils.getDottedText(this.props.commandParams.uiCommand.name.uiName, 40)}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <IconButton title="Back" onClick={this.stepBack.bind(this)}>
              <KeyboardReturnIcon />
            </IconButton>
            <IconButton name="Close" title="Close" onClick={this.closePanel.bind(this, "hideCommandParams")}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            {this.determineMultiAllowed() && (
              <div elevation={3}>
                <Grid container alignItems="center" direction="row" spacing={8} className="command-params-column-select">
                  <Grid item xs={4}>
                    <IconButton
                      className="submitParamsButton paramsButton"
                      title="Multi select columns"
                      onClick={this.multiSelectToggle.bind(this)}
                      style={{
                        color: multiButtonColour,
                      }}
                    >
                      <LibraryAddIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <ListItemText primary={"Multi Select"} secondary={this.state.multiSelectionEnabled ? "True" : "False"} />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={8}>
            <CommandParamInputs
              mode={this.props.mode}
              commandParams={this.props.commandParams}
              commandResultArray={this.props.commandResultArray}
              multiSelectionEnabled={this.state.multiSelectionEnabled}
            />
          </Grid>
          <Grid item xs={2}>
            {CommandParamsRules()}
          </Grid>
          <Grid item xs={12}>
            <p id="ruleDescriptorText"></p>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  //TODO: Params which are not required can fail validation when empty because their userInputargs==undefined
  validateArguments() {
    //If the command requires no parameters, apply the rule and return to the applied commands list
    if (this.props.commandParams.uiCommand.argsSpec.length == 0) {
      this.insertRule();
      tbAppChannel.publish("hideCommandParams", {});
    }
    if (this.props.commandParams.userInputargs == undefined) return false;
    let argsList = this.props.commandParams.uiCommand.argsSpec.map((argsNameWraper, index) => {
      if (argsNameWraper.required) {
        if (this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined) return false;
        if (this.props.commandParams.userInputargs[argsNameWraper.peName] === "") return false;
        if (argsNameWraper.peName == "columnIndexToDelete" && this.props.commandParams.isNew == undefined) {
          return false;
        }
        if (argsNameWraper.type == "columnIndex") {
          if (Array.isArray(this.props.commandParams.userInputargs[argsNameWraper.peName])) {
            this.props.commandParams.userInputargs[argsNameWraper.peName].forEach((element) => {
              if (isNaN(element.index) && element.value == undefined) return false;
            });
          } else if (
            isNaN(this.props.commandParams.userInputargs[argsNameWraper.peName].index) &&
            this.props.commandParams.userInputargs[argsNameWraper.peName].value == undefined
          ) {
            return false;
          }
        }
      }
    });
    return !argsList.includes(false);
  }

  updateRule() {
    let currentstep = appSingleton.step;

    if (currentstep == undefined) {
      currentstep = -1;
    } else {
      currentstep = parseInt(currentstep);
    }

    let { commandParams } = this.props;
    let formArgs = this.props.commandParams.uiCommand.name.peName === "lumsCommand" ? this.lumsCommand(commandParams) : this.serializeForm();

    formArgs["commandIndex"] = currentstep;
    formArgs["updateType"] = "updateCommand";
    HttpMediator.updateCommand(formArgs);
    this.setState({ multiSelectionEnabled: false });
    tbAppChannel.publish("hideCommandParams", {});
  }

  /**
   *
   * @param formArgs
   * @returns {boolean}
   */
  ruleToGenerateNewColumnIsLastInStack = (formArgs) => {
    const commandsWhichCreateANewColumn = [
      "mapAndCalculateCommand",
      "rateLookupCommand",
      "aliasTableHandler",
      "addColumn",
      "copyColumn",
      "lookupServiceCommand",
      "lumsEqualsCommand",
      "validateByRuleCommand",
    ];

    //If the rule being inserted creates a new column, ensure it is not being inserted in the middle of the stack
    if (commandsWhichCreateANewColumn.includes(formArgs.commandName)) {
      return formArgs.commandIndex == this.props.commandStackLength;
    } else {
      return true;
    }
  };

  lumsCommand(formArgs) {
    formArgs = this.props.commandParams.userInputargs;
    formArgs["commandName"] = this.props.commandParams.uiCommand.name.peName;
    return formArgs;
  }

  insertRule() {
    let currentstep = appSingleton.step;

    if (currentstep == undefined) {
      currentstep = -1;
    } else {
      currentstep = parseInt(currentstep);
    }

    let { commandParams } = this.props;

    let formArgs = this.props.commandParams.uiCommand.name.peName === "lumsCommand" ? this.lumsCommand(commandParams) : this.serializeForm();

    formArgs["commandIndex"] = currentstep + 1;
    if (!this.ruleToGenerateNewColumnIsLastInStack(formArgs)) {
      tbAppChannel.publish("error", "Rules which create columns can only be appended to the list");
      return;
    }
    formArgs["updateType"] = "insertCommand";
    HttpMediator.updateCommand(formArgs);
    tbAppChannel.publish("hideCommandParams", {});
  }

  serializeForm() {
    let formArgs = new Object();
    formArgs["commandName"] = this.props.commandParams.uiCommand.name.peName;
    if (this.props.commandParams.uiCommand.argsSpec.length == 0) return formArgs;
    Object.keys(this.props.commandParams.userInputargs).forEach((argumentName) => {
      if (this.props.commandParams.userInputargs[argumentName] instanceof IndexedValue) {
        formArgs[argumentName] = this.props.commandParams.userInputargs[argumentName].index.toString();
      } else if (Array.isArray(this.props.commandParams.userInputargs[argumentName])) {
        let indices = new Array();
        this.props.commandParams.userInputargs[argumentName].forEach((element) => {
          if (element instanceof IndexedValue) {
            indices.push(element.index);
          }
          formArgs[argumentName] = indices;
        });
      } else formArgs[argumentName] = this.props.commandParams.userInputargs[argumentName];
    });
    return formArgs;
  }

  determineMultiAllowed() {
    let columnIndexFiltered = this.props.commandParams.uiCommand.argsSpec.filter((argsNameWrapper) => {
      return argsNameWrapper.type === "columnIndex";
    });
    return columnIndexFiltered.length !== 0 ? columnIndexFiltered[0].multiSelectAllowed : false;
  }

  multiSelectToggle() {
    this.setState((prevState) => ({
      multiSelectionEnabled: !prevState.multiSelectionEnabled,
    }));
  }

  /**
   * Called when the back button is clicked
   * If the user is on the first step, check props.mode to view either the Applied Rules or complete rules list
   * Otherwise, ensure all steps including and greater than the current one are deleted
   * If greater than 0, decrement state.currentStep by one
   */
  stepBack() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 0) {
      if (this.props.mode == "Edit") {
        this.closePanel("hideCommandParams");
      } else if (this.props.mode == "New") {
        this.closePanel("hideCommandParamsBackToCommandList");
      }
    }
    let stepNumbers = Object.keys(this.state.steps);
    stepNumbers.forEach(function (stepNumber) {
      if (stepNumber >= currentStep) {
        delete this.state.steps[stepNumber];
      }
    });
    this.state.currentStep = currentStep > 0 ? (currentStep -= 1) : 0;
  }

  /**
   * Called when the close button is clicked
   * Resets state for the panel
   * Returns user to applied rules list view
   */
  closePanel(subscriptionString) {
    this.setState({ steps: {}, currentStep: 0 });
    tbAppChannel.publish(subscriptionString);
    //Code needed here to check for (and delete) saved selection
  }
}
