/**
 * Created by Naman on 10/11/2016.
 */
import { tbAppChannel } from "../communication/AppChannels";
import { appSingleton } from "../AppSingleton";
import { fusionDataApi } from "../../../../api/index";
import { uiStore, tbStore } from "../../../../store/Store";
/**
 * Responsible for updating urls and delegating http request to api using axios*
 */
export default class HttpMediator {
  static parseFile(formData, callback) {
    let fileUploadRespone = fusionDataApi.tbUi.draft(formData);
    return fileUploadRespone.then((jsonResponse) => {
      const { status } = jsonResponse;

      if (status === 200) {
        appSingleton.templateUuid = jsonResponse.data.templateUuid;
        appSingleton.step = 0;
        if (callback != undefined) callback();
        tbAppChannel.publish("dataRefresh", {
          commandResultArrayAndCommandStack: jsonResponse.data,
        });
      } else {
        tbAppChannel.publish("error", jsonResponse.data.errorMessage);
      }
    });
  }

  static updateParseFile(formData, callback) {
    //fileName needed as param for selected commands in TB
    let fileName = appSingleton.allFileS3Urns ? appSingleton.allFileS3Urns[0][0] : "";

    formData.appliedFileName = fileName;
    let fileUpdateRespone = fusionDataApi.tbUi.updateParseFile({ ...formData, templateUuid: appSingleton.templateUuid, teamId: appSingleton.teamId });

    return fileUpdateRespone.then((jsonResponse) => {
      const { status } = jsonResponse;

      if (status === 200) {
        appSingleton.step = 0;

        tbAppChannel.publish("dataRefresh", {
          commandResultArrayAndCommandStack: jsonResponse.data,
        });

        if (callback != undefined) callback();
      } else {
        tbAppChannel.publish("error", jsonResponse.data.errorMessage);
      }
    });
  }

  static async changeSampleFile(formData) {
    try {
      const response = await fusionDataApi.tbUi.updateSampleFile(appSingleton.teamId, appSingleton.templateUuid, formData);

      const { status, data } = response;

      if (status === 200) {
        appSingleton.step = 0;
        tbAppChannel.publish("dataRefresh", {
          commandResultArrayAndCommandStack: data,
        });
        let sampleFile = data?.allFileS3Urns ? data?.allFileS3Urns[0] : [];

        tbStore.templateSampleFile = sampleFile;
        uiStore.addNotification("success", `Sample file successfully applied`);

        return { ...response, success: true };
      } else {
        uiStore.addNotification("error", "Unable to apply sample file! Please try again");
        tbAppChannel.publish("error", data.errorMessage);

        return { ...response, success: false };
      }
    } catch (error) {
      uiStore.addNotification("error", "Unable to apply sample file! Please try again");

      console.error("Error applying sample file:", error.message);
      return { success: false, error: error.message };
    }
  }

  static applyCommand(requestParamsJson, callback) {
    let commandAppliedPromise = fusionDataApi.tbUi.applyCommand({ ...requestParamsJson, templateUuid: appSingleton.templateUuid });
    let consumedPromise = commandAppliedPromise.then((jsonResponse) => {
      appSingleton.step = jsonResponse.dataReturned.commandStack.length - 1;

      tbAppChannel.publish("dataRefresh", {
        commandResultArrayAndCommandStack: jsonResponse.data,
      });
    });
    return consumedPromise;
  }

  static updateCommand(requestParamsJson, callback) {
    let commandAppliedPromise;
    //fileName needed as param for selected commands in TB
    requestParamsJson.appliedFileName = appSingleton.allFileS3Urns[0][0];
    if (requestParamsJson.updateType === "deleteCommand") {
      commandAppliedPromise = fusionDataApi.tbUi.deleteCommand({ ...requestParamsJson, templateUuid: appSingleton.templateUuid, teamId: appSingleton.teamId });
    } else {
      commandAppliedPromise = fusionDataApi.tbUi.updateCommand({ ...requestParamsJson, templateUuid: appSingleton.templateUuid, teamId: appSingleton.teamId });
    }
    let consumedPromise = commandAppliedPromise.then((jsonResponse) => {
      if (requestParamsJson.updateType === "deleteCommand") {
        const { status } = jsonResponse;

        if (status === 200) {
          uiStore.addNotification("success", `Rule successfully deleted`);

          appSingleton.step = jsonResponse.data.commandStack.length - 1;
          tbAppChannel.publish("dataRefresh", {
            commandResultArrayAndCommandStack: jsonResponse.data,
          });
        } else {
          tbAppChannel.publish("error", jsonResponse.data.errorMessage);
        }
      } else {
        const { status } = jsonResponse;

        if (status === 200) {
          uiStore.addNotification("success", `Rule successfully added`);
          appSingleton.step = requestParamsJson["commandIndex"];
          tbAppChannel.publish("dataRefresh", {
            commandResultArrayAndCommandStack: jsonResponse.data,
          });
        } else {
          tbAppChannel.publish("error", jsonResponse.data.errorMessage);
        }
      }
    });
    return consumedPromise;
  }

  //update a command but don'ty change step, used for previewing select and transform
  static updateCommandTemp(requestParamsJson, callback) {
    //fileName needed as param for selected commands in TB
    requestParamsJson.appliedFileName = appSingleton.allFileS3Urns ? appSingleton.allFileS3Urns[0][0] : "";
    let commandAppliedPromise = fusionDataApi.tbUi.updateCommand({
      ...requestParamsJson,
      templateUuid: appSingleton.templateUuid,
      teamId: appSingleton.teamId,
    });

    let consumedPromise = commandAppliedPromise.then((jsonResponse) => {
      appSingleton.step = requestParamsJson["commandIndex"];

      //       tbAppChannel.publish("dataRefresh",{commandResultArrayAndCommandStack:jsonResponse.dataReturned})
    });
    return consumedPromise;
  }

  static undoCommand() {
    //fileName needed as param for selected commands in TB
    let commandAppliedPromise = fusionDataApi.tbUi.undo({
      appliedFileName: appSingleton.allFileS3Urns[0][0],
      templateUuid: appSingleton.templateUuid,
      teamId: appSingleton.teamId,
    });
    let consumedPromise = commandAppliedPromise.then((jsonResponse) => {
      const { status } = jsonResponse;

      if (status === 200) {
        appSingleton.step = jsonResponse.data.commandStack.length - 1;

        tbAppChannel.publish("dataRefresh", {
          commandResultArrayAndCommandStack: jsonResponse.data,
        });
      } else {
        tbAppChannel.publish("error", jsonResponse.data.errorMessage);
      }
    });
    return consumedPromise;
  }

  static fetchResultAndStack(step, callback) {
    //fileName needed as param for selected commands in TB

    let fileName = appSingleton.allFileS3Urns ? appSingleton.allFileS3Urns[0][0] : "";
    let tbStatePromise = fusionDataApi.tbUi.getResultAndStack({
      teamId: appSingleton.teamId,
      appliedFileName: fileName,
      pos: step.toString(),
      templateUuid: appSingleton.templateUuid,
    });
    return tbStatePromise.then((jsonResponse) => {
      const { status } = jsonResponse;
      if (status === 200) {
        tbAppChannel.publish("dataRefresh", {
          commandResultArrayAndCommandStack: jsonResponse.data,
          step: step,
        });

        let sampleFile = jsonResponse?.data?.allFileS3Urns ? jsonResponse?.data?.allFileS3Urns[0] : [];

        tbStore.templateSampleFile = sampleFile;
        if (callback != undefined) callback();
      } else {
        tbAppChannel.publish("error", jsonResponse.data.errorMessage);
      }
    });
  }

  static fetchCommandsList() {
    //fileName needed as param for selected commands in TB
    let fileName = appSingleton.allFileS3Urns ? appSingleton.allFileS3Urns[0][0] : "";
    return fusionDataApi.tbUi.commandsList({
      appliedFileName: fileName,
      teamId: appSingleton.teamId,
      templateUuid: appSingleton.templateUuid,
    });
  }

  //for readOnly, and other mostly static properties, loaded on page reload only
  static fetchTemplateProperties() {
    return fusionDataApi.tbUi.getTemplateProperties({ templateUuid: appSingleton.templateUuid });
  }

  static fetchTemplateMappings() {
    return fusionDataApi.tbUi.getRequiredTemplateMapping({ teamId: appSingleton.teamId, templateUuid: appSingleton.templateUuid });
  }

  static saveTemplateMappings(mappings) {
    return fusionDataApi.tbUi.saveTemplateMappings({
      templateMapping: mappings,
      teamId: appSingleton.teamId,
      templateUuid: appSingleton.templateUuid,
    });
  }

  static submitTemplate() {
    return fusionDataApi.tbUi.submitTemplate({ templateUuid: appSingleton.templateUuid });
  }

  static approveTemplate() {
    return fusionDataApi.tbUi.approveTemplate({ templateUuid: appSingleton.templateUuid });
  }

  static rejectTemplate() {
    return fusionDataApi.tbUi.rejectTemplate({ templateUuid: appSingleton.templateUuid });
  }

  // static deleteTemplate() {
  //   return HttpRequest.postJson(
  //     TEMPLATE_BUILDER_API_PREFIX + DELETE_TEMPLATE,
  //     {}
  //   );
  // }

  static getTmos() {
    return fusionDataApi.tbUi.getTmos({
      teamId: appSingleton.teamId,
      templateUuid: appSingleton.templateUuid,
    });
  }

  static async updateTemplateType(typeId) {
    try {
      const response = await fusionDataApi.tbUi.updateTemplateType({
        teamId: appSingleton.teamId,
        templateUuid: appSingleton.templateUuid,
        tmoUuid: typeId,
      });

      const { status } = response;

      if (status === 200) {
        tbStore.outputMappingUuid = typeId;

        uiStore.addNotification("success", "Output mapping successfully applied");
        HttpMediator.fetchResultAndStack("-1");

        return { ...response, success: true };
      } else {
        uiStore.addNotification("error", "Unable to apply output mapping! Please try again");

        return { ...response, success: false };
      }
    } catch (error) {
      uiStore.addNotification("error", "Unable to apply output mapping! Please try again");

      console.error("Error applying output mapping:", error.message);
    }
  }
}
