import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Button, Panel, PanelBody, PanelHeader, PanelFooter, Input, modalInstance, IconCheck } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { MODAL_IDS } from "utils/templates/enums";
import { FILE_SIZE } from "utils/enums";
import { appSingleton } from "TemplateBuilder/components/utils/AppSingleton";
import HttpMediator from "TemplateBuilder/components/utils/http/HttpMediator";
import styles from "./ChangeSampleFile.module.scss";

const ChangeSampleFile = observer(() => {
  const { tbStore } = useStore();

  const handleClose = () => {
    setSampleFile(tbStore.templateSampleFile);
    inputRef.current.value = "";
    modalInstance(MODAL_IDS.CHANGE_SAMPLE_FILE).hide();
  };

  const [sampleFile, setSampleFile] = useState(null);
  const [newFile, setNewFile] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    setNewFile(null);
    inputRef.current.value = "";
    setSampleFile(tbStore.templateSampleFile);
  }, [tbStore.templateSampleFile]);

  const applySampleFile = async () => {
    let formData = new FormData();

    if (sampleFile) {
      const [sampleFileName, sampleFileAndUuid] = sampleFile;
      formData.append("fileName", sampleFileName);
      formData.append("fileS3Urn", sampleFileAndUuid);
      formData.append("file", undefined);
    }

    if (newFile) {
      const [file] = newFile;
      formData.append("file", file);
    }

    formData.append("pos", appSingleton.step);
    formData.append("templateUuid", appSingleton.templateUuid);
    const { success } = await HttpMediator.changeSampleFile(formData);
    if (success) {
      modalInstance(MODAL_IDS.CHANGE_SAMPLE_FILE).hide();
    }
  };

  return (
    <Panel panelId={MODAL_IDS.CHANGE_SAMPLE_FILE}>
      <PanelBody>
        <PanelHeader header="Select or upload sample file" onClick={handleClose} />
        <div className="pt-24">
          <Text size="sm" weight="medium" variant="secondary">
            Upload a new file
          </Text>
        </div>
        <div className="pt-12">
          <Input
            onChange={() => {
              setNewFile(inputRef.current.files);
              setSampleFile(null);
            }}
            type="file"
            size="md"
            innerRef={inputRef}
            warningMessage={FILE_SIZE.MAX_WARNING}
            warning={false}
            spacers={[]}
          />
        </div>
        <div className="pt-32 ">
          <Text size="sm" weight="medium" variant="secondary" classes="mb-12" element="div">
            Or choose from recently uploaded files
          </Text>

          <div style={{ height: "50vh", overflowY: "auto" }} className="pt-0 pb-12 pl-0">
            {appSingleton.allFileS3Urns.map((fileAndUrn, index) => {
              const [fileName, fileAndUuid] = fileAndUrn ?? [];
              const [, sampleFileAndUuid] = sampleFile ?? [];

              return (
                <div
                  onClick={() => {
                    inputRef.current.value = "";
                    setNewFile(null);
                    setSampleFile(fileAndUrn);
                  }}
                  className={[
                    `${fileAndUuid !== sampleFileAndUuid && styles.cursorPointer}`,
                    `${styles.sampleElement}`,
                    " d-flex mb-12 pb-8 pt-8 pl-12 pr-12",
                    `${fileAndUuid === sampleFileAndUuid && styles.activeText}`,
                    `${fileAndUuid === sampleFileAndUuid && styles.activeBackground}`,
                  ].join(" ")}
                  key={index}
                >
                  <div className="me-auto">{fileName}</div>
                  <div>{fileAndUuid === sampleFileAndUuid && <IconCheck className={styles.checkIconSize} />}</div>
                </div>
              );
            })}
          </div>
        </div>
      </PanelBody>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={applySampleFile} size="md">
          <Text size="sm">Apply sample file</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { ChangeSampleFile };
