import React from "react";
import {
  Text,
  C10,
  PanelHeader,
  PanelBody,
  Panel,
  R,
  C,
  modalInstance,
  IconInformation,
  IconAiAssistant,
  Heading,
  IconNew,
  ToolTipPortal,
} from "@fundrecs/ui-library";
import CommandUtils from "../../utils/CommandUtils";
import { tbAppChannel } from "../../utils/communication/AppChannels";
import { appSingleton } from "../../utils/AppSingleton";
import { ADD_NEW_RULE, AI_ASSISTANT_RULE } from "utils/templates/enums";
import { TEMPLATE_STRUCTURE } from "TemplateBuilder/components/utils/enums";
import styles from "./GeneralCommands.module.scss";

const Command = ({ count, heading, description, command }) => {
  return (
    <div
      key={count}
      className={styles.ruleSection}
      onClick={() => {
        command();
      }}
    >
      <R props="pl-8 pt-8 pb-8 pr-8">
        <C10 props="mt-0 mb-0 pl-0 pr-0">
          <div className="d-flex align-items-center" name="command">
            {heading}
          </div>
        </C10>
        <C props="t-0 mb-0 pl-0 pr-0">
          <div className="d-flex justify-content-end">
            <ToolTipPortal text={description} direction="left">
              <IconInformation className="light-text-muted icon-size" />
            </ToolTipPortal>
          </div>
        </C>
      </R>
    </div>
  );
};

const AiAssistant = ({ count, command }) => {
  return (
    <>
      <div
        className={["pb-24 pt-24 pl-24 pr-32 ", `${styles.aiAssistant}`, "d-flex", `${styles.aiAssistantBorder}`].join(" ")}
        key={count}
        onClick={() => {
          command();
        }}
      >
        <div className="flex-fill pr-20">
          <IconAiAssistant />
        </div>
        <div className={["flex-fill", `${styles.aiAssistant}`].join(" ")}>
          <Heading variant="h6">
            Use our AI Assistant <IconNew className="ml-8" />
          </Heading>

          <Text weight="regular" size="sm">
            Effortlessly transform your data - just tell our AI assistant what transformation you need, and it handles the rest by configuring and applying the
            rule for you!
          </Text>
        </div>
      </div>
      <div className="d-flex pt-12 pb-12">
        <div className="section-divider mt-8" />
        <Text size="xs" weight="bold" classes="pb-10 pr-8 pl-8" element="div">
          OR
        </Text>
        <div className="section-divider mt-8" />
      </div>
    </>
  );
};

class GeneralCommands extends React.Component {
  constructor(props) {
    super(props);
    this.state = { command: undefined, aiAssistantActive: false };

    this.hasPeNameValue(appSingleton.generalCommands, AI_ASSISTANT_RULE);
  }

  /**
   * The function assumes data is always an array.
   * We use .some() to iterate through the array and check if:
   * The object contains "peName" and matches targetValue.
   */
  hasPeNameValue = (data, targetValue) => {
    return data.some((item) => item.name.peName === targetValue || item.argsSpec.some((arg) => arg.peName === targetValue));
  };

  componentDidUpdate() {
    if (this.props.isVisible) {
      modalInstance(ADD_NEW_RULE).toggle();
    } else {
      modalInstance(ADD_NEW_RULE).hide();
    }
  }

  render() {
    let testCommands = appSingleton.generalCommands;
    var groupMap = new Map(); //map of Maps of Lists
    for (var i = 1; i < 11; i++) {
      groupMap.set(i + "", new Map());
    }

    testCommands.forEach((command) => {
      if (command.name.reqStructure === appSingleton.state) {
        let groupAndOrder = command.name.order.split(",");
        var group = groupMap.get(groupAndOrder[0]);
        var positionList = group.get(groupAndOrder[1]);
        if (positionList == undefined) {
          positionList = [];
        }

        positionList.push(command);

        group.set(groupAndOrder[1], positionList);
      }
    });

    let commandsListHtml = [];
    var count = 0;
    for (var i = 1; i < 11; i++) {
      var group = groupMap.get(i + "");
      if (group.size > 0) {
        for (var x = 1; x < 20; x++) {
          var uiCommandList = group.get(x + "");
          if (uiCommandList != undefined) {
            uiCommandList.forEach((uiCommand) => {
              if (uiCommand?.name?.peName !== AI_ASSISTANT_RULE) {
                commandsListHtml.push(
                  <Command
                    key={count}
                    count={count}
                    heading={uiCommand.name.uiName}
                    description={uiCommand.name.uiDesc}
                    command={() => {
                      this.commandSelected(uiCommand);
                    }}
                  />
                );

                count++;
              }
            });
          }
        }
        commandsListHtml.push(<div key={count} className="section-divider mt-4 mb-4" />);
        count++;
      }
    }

    if (appSingleton.state == TEMPLATE_STRUCTURE.STRUCTURED) {
      count++;
      commandsListHtml.unshift(<div key={count} className="section-divider mt-4 mb-4" />);
      count++;
      commandsListHtml.unshift(
        <Command
          key={count}
          count={count}
          heading="Filter"
          description="Filter rows based on conditions "
          command={() => {
            this.sAndTcommandSelected("Filter");
          }}
        />
      );
      count++;
      commandsListHtml.unshift(
        <Command
          key={count}
          count={count}
          heading="Modify"
          description="Modify a column based on conditions and a selected transformation "
          command={() => {
            this.sAndTcommandSelected("Modify");
          }}
        />
      );
      count++;
      commandsListHtml.unshift(
        <Command
          key={count}
          count={count}
          heading="Merge"
          description="Merge 2 columns based on conditions and a selected transformation "
          command={() => {
            this.sAndTcommandSelected("Merge");
          }}
        />
      );

      count++;
      commandsListHtml.unshift(
        <Text key={count} size="sm" className="pb-12">
          Select the rule you wish to manually configure
        </Text>
      );

      if (this.hasPeNameValue(testCommands, AI_ASSISTANT_RULE)) {
        count++;
        commandsListHtml.unshift(
          <AiAssistant
            key={count}
            count={count}
            command={() => {
              this.commandSelected({ name: { peName: AI_ASSISTANT_RULE } });
            }}
          />
        );
      }
      count++;
      commandsListHtml.push(
        <Command
          key={count}
          count={count}
          heading="Swap Columns"
          description="Swap Columns"
          command={() => {
            this.sAndTcommandSelected("SwapColumns");
          }}
        />
      );
      count++;
      commandsListHtml.push(
        <Command
          key={count}
          count={count}
          heading="Copy and modify rows"
          description="Copy and modify rows"
          command={() => {
            this.sAndTcommandSelected("CopyAndModifyRows");
          }}
        />
      );
      count++;
      commandsListHtml.push(
        <Command
          key={count}
          count={count}
          heading="Find duplicate and copy larger value"
          description="Find duplicate and copy larger value"
          command={() => {
            this.sAndTcommandSelected("findDuplicateAndCopyLargerValue");
          }}
        />
      );
    }

    if (appSingleton.state !== TEMPLATE_STRUCTURE.STRUCTURED) {
      if (this.hasPeNameValue(testCommands, AI_ASSISTANT_RULE)) {
        count++;
        commandsListHtml.unshift(
          <AiAssistant
            key={count}
            count={count}
            command={() => {
              this.commandSelected({ name: { peName: AI_ASSISTANT_RULE } });
            }}
          />
        );
      }
      count++;
      commandsListHtml.unshift(
        <Text key={count} size="sm" classes="pb-12" element="div">
          Select the rule you wish to manually configure
        </Text>
      );
    }

    return (
      <>
        <Panel panelId={ADD_NEW_RULE}>
          <PanelBody classes={styles.hidePanelOverflow}>
            <PanelHeader
              header="Add a new rule"
              onClick={() => {
                tbAppChannel.publish("hideCommandParams", {});
                this.setState({
                  command: undefined,
                });
              }}
            />

            <div className={styles.ruleList}>
              <div className="mr-8">{commandsListHtml}</div>
            </div>
          </PanelBody>
        </Panel>
      </>
    );
  }

  commandSelected = ({ name: { peName } }) => {
    let uiCommandSelected = CommandUtils.findUiCommand(peName);
    tbAppChannel.publish("newGeneralCommandSelected", {
      uiCommand: uiCommandSelected,
    });
  };

  sAndTcommandSelected = (commandSelectedName) => {
    tbAppChannel.publish("addModeSandT", commandSelectedName);
  };
}

export default GeneralCommands;
