import { useEffect, useState } from "react";
import { fusionDataApi } from "api";
import { useTeamId } from "store/hooks/useTeamId";

const useDestinationSnowflakeData = (workflowUuid) => {
  const teamId = useTeamId();

  const [destinationSnowflakeData, setDestinationSnowflakeData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data, status } = await fusionDataApi.workflows.getDestinationSnowflake(teamId, workflowUuid);

      const warehouses = data?.warehouses ?? [];
      const warehouse = warehouses[0] ?? { name: "", id: null, databases: [] };

      if (status === 200) {
        setDestinationSnowflakeData(warehouse);
      }
    };

    fetchData();
  }, []);

  return destinationSnowflakeData;
};

export { useDestinationSnowflakeData };
