import { useEffect, useContext } from "react";

import { FE_SOURCE_TYPES } from "utils/enums";
import { ArrayOptionDropdown } from "../../DropdownOptions/ArrayOptionDropdown";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const InputSourceView = ({
  option,
  emailData,
  sftpData,
  selectedImportEmail,
  setSelectedImportEmail,
  selectedImportSftpConnection,
  setSelectedImportSftpConnection,
  selectedSftpFolder,
  setSelectedSftpFolder,
  workflowSource,
  setWorkflowSource,
  sourceWorkflowsData,
  sourceSnowflakeData,
  setSnowflakeDatabase,
  snowflakeDatabase,
  setSnowflakeSchema,
  snowflakeSchema,
  setSnowflakeTable,
  snowflakeTable,
}) => {
  const { workflow } = useContext(WorkflowConfigurationContext);

  //Setting a default value for connectionUuid because it won't be present if we have no source yet.
  const { connectionUuid = "", inputMappingId = "", workflowUuid = "", type, databaseName, schemaName, tableName } = option;

  const workflowSources = sourceWorkflowsData.filter((source) => source.uuid !== workflow.uuid);

  useEffect(() => {
    if (sftpData.length > 0 && type === FE_SOURCE_TYPES.SFTP && !Object.hasOwn(selectedImportSftpConnection, "connectionUuid")) {
      setSelectedImportSftpConnection({ ...getSelectedSource(sftpData, connectionUuid) });
      setSelectedSftpFolder(getSelectedInputMapping(getSelectedSource(sftpData, connectionUuid), inputMappingId));
    }

    if (emailData.length > 0 && type === FE_SOURCE_TYPES.EMAIL && !Object.hasOwn(selectedImportEmail, "connectionUuid")) {
      setSelectedImportEmail({ ...getSelectedSource(emailData, connectionUuid) });
    }

    if (workflowSources.length > 0 && type === FE_SOURCE_TYPES.WORKFLOW) {
      setWorkflowSource({ ...getSelectedWorkflow(workflowSources, workflowUuid) });
    }

    if (sourceSnowflakeData.length > 0 && type === FE_SOURCE_TYPES.SNOWFLAKE) {
      setSnowflakeDatabase({ ...getSelectedSnowflakeDatabase(sourceSnowflakeData, databaseName) });
      setSnowflakeSchema({ ...getSelectedSnowflakeSchema(getSelectedSnowflakeDatabase(sourceSnowflakeData, databaseName), schemaName) });
      setSnowflakeTable({
        ...getSelectedSnowflakeTable(getSelectedSnowflakeSchema(getSelectedSnowflakeDatabase(sourceSnowflakeData, databaseName), schemaName), tableName),
      });
    }
  }, [emailData, sftpData, type]);

  const updateImportSftpConnection = (index, value) => {
    setSelectedImportSftpConnection({ index, ...value });
    setSelectedSftpFolder(getSelectedInputMapping({ index, ...value }, inputMappingId));
  };

  const updateImportEmailAddress = (index, value) => {
    setSelectedImportEmail({ index, ...value });
  };

  const updateSftpFolder = (index, value) => {
    setSelectedSftpFolder({ index, ...value });
  };

  const updateWorkflowSource = (index, value) => {
    setWorkflowSource({ index, ...value });
  };

  const updateSnowflakeDatabase = (index, value) => {
    setSnowflakeDatabase({ index, ...value });
    setSnowflakeSchema(getSelectedSnowflakeSchema({ index, ...value }, schemaName));
    setSnowflakeTable(getSelectedSnowflakeTable(getSelectedSnowflakeSchema({ index, ...value }, schemaName), tableName));
  };

  const updateSnowflakeSchema = (index, value) => {
    setSnowflakeSchema({ index, ...value });
    setSnowflakeTable(getSelectedSnowflakeTable({ index, ...value }, tableName));
  };

  const updateSnowflakeTable = (index, value) => {
    setSnowflakeTable({ index, ...value });
  };

  /**
   * This will assemble the selected object for email/sftp if a source exists already.
   * If none exist it will set a default.
   *
   * This method returns an object.
   *
   * @param data is an array of objects
   */
  const getSelectedSource = (data, connectionUuid) => {
    /**
     * Find matching connection and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const sourceConnection = data.find((connection) => connection.connectionUuid === connectionUuid);
    const sourceConnectionIndex = data.findIndex((connection) => connection.connectionUuid === connectionUuid);
    /**
     * Check if source connection is undefined.
     * if it is set the default. We should have a default if we are at this point because we checked for sftpData length.
     * Otherwise if it's not undefined then construct the existing connection.
     * **/
    const selectedConnection = sourceConnection === undefined ? { index: 0, ...data[0] } : { index: sourceConnectionIndex, ...sourceConnection };
    return selectedConnection;
  };

  const getSelectedInputMapping = (selectedImportSftpConnection, inputMappingId) => {
    const inputMapping = selectedImportSftpConnection.inputMappings.find((mapping) => mapping.id === inputMappingId);
    const inputMappingIndex = selectedImportSftpConnection.inputMappings.findIndex((mapping) => mapping.id === inputMappingId);

    //Set defaultInputMapping as selected if the inputMappings array has any objects otherwise set empty object
    const defaultInputMapping = selectedImportSftpConnection.inputMappings.length > 0 ? selectedImportSftpConnection.inputMappings[0] : {};
    const selectedInputMapping = inputMapping === undefined ? defaultInputMapping : { index: inputMappingIndex, ...inputMapping };
    return selectedInputMapping;
  };

  const getSelectedWorkflow = (data, workflowUuid) => {
    /**
     * Find matching workflow and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const workflowSource = data.find((workflow) => workflow.uuid === workflowUuid);
    const workflowSourceIndex = data.findIndex((workflow) => workflow.uuid === workflowUuid);
    /**
     * Check if workflow is undefined if it is set the default for now.
     * **/
    const selectedWorkflow = workflowSource === undefined ? { index: 0, ...data[0] } : { index: workflowSourceIndex, ...workflowSource };

    return selectedWorkflow;
  };

  const getSelectedSnowflakeDatabase = (data, databaseName) => {
    const snowflakeDatabase = data.find((database) => database.name === databaseName);
    const snowflakeDatabaseIndex = data.findIndex((database) => database.name === databaseName);

    const selectedSnowflakeDatabase = snowflakeDatabase === undefined ? { index: 0, ...data[0] } : { index: snowflakeDatabaseIndex, ...snowflakeDatabase };

    return selectedSnowflakeDatabase;
  };

  const getSelectedSnowflakeSchema = (data, schemaName) => {
    const { schemas } = data;

    const snowflakeSchema = schemas.find((schema) => schema.name === schemaName);
    const snowflakeSchemaIndex = schemas.findIndex((schema) => schema.name === schemaName);

    const selectedSnowflakeSchema = snowflakeSchema === undefined ? { index: 0, ...schemas[0] } : { index: snowflakeSchemaIndex, ...snowflakeSchema };

    return selectedSnowflakeSchema;
  };

  const getSelectedSnowflakeTable = (data, tableName) => {
    const { tables } = data;

    const snowflakeTable = tables.find((table) => table.name === tableName);
    const snowflakeTableIndex = tables.findIndex((table) => table.name === tableName);

    const selectedSnowflakeTable = snowflakeTable === undefined ? { index: 0, ...tables[0] } : { index: snowflakeTableIndex, ...snowflakeTable };

    return selectedSnowflakeTable;
  };

  return (
    <>
      {FE_SOURCE_TYPES.WORKFLOW === type && (
        <ArrayOptionDropdown label="Choose workflow" options={workflowSources} option={workflowSource} setOption={updateWorkflowSource} objKey="name" />
      )}
      {FE_SOURCE_TYPES.SFTP === type && (
        <>
          <ArrayOptionDropdown
            label="Choose SFTP connection"
            options={sftpData}
            option={selectedImportSftpConnection}
            setOption={updateImportSftpConnection}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose SFTP directory"
            options={selectedImportSftpConnection.inputMappings}
            disabled={selectedImportSftpConnection.inputMappings.length === 0}
            option={selectedSftpFolder}
            setOption={updateSftpFolder}
            objKey="name"
            objKey2="fileSource"
          />
        </>
      )}

      {FE_SOURCE_TYPES.SNOWFLAKE === type && (
        <>
          <ArrayOptionDropdown
            label="Choose database"
            options={sourceSnowflakeData}
            option={snowflakeDatabase}
            setOption={updateSnowflakeDatabase}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose schema"
            options={snowflakeDatabase?.schemas}
            disabled={snowflakeDatabase?.schemas?.length === 0}
            option={snowflakeSchema}
            setOption={updateSnowflakeSchema}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose table"
            options={snowflakeSchema?.tables}
            disabled={snowflakeSchema?.tables?.length === 0}
            option={snowflakeTable}
            setOption={updateSnowflakeTable}
            objKey="name"
          />
        </>
      )}
      {FE_SOURCE_TYPES.EMAIL === type && (
        <>
          <ArrayOptionDropdown
            label="Choose email address"
            options={emailData}
            option={selectedImportEmail}
            setOption={updateImportEmailAddress}
            objKey2="emailAddress"
            objKey="name"
          />
        </>
      )}
    </>
  );
};

export { InputSourceView };
