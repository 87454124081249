import { useEffect, useState } from "react";
import { useStore } from "store/Store";

const useTemplateAndOutputMappingName = (templateUuid, teamId) => {
  const { templatesStore, tbStore } = useStore();

  const [template, setTemplate] = useState({ name: "", description: "" });
  const [outputMappingName, setOutputMappingName] = useState("");
  const [outputMappingUuid, setOutputMappingUuid] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const { data, success } = await templatesStore.getTemplateByUuidAndTeamId(templateUuid, teamId);
      if (success) {
        const {
          tbTemplate: {
            tmoDetail: { name, uuid },
          },
        } = data;
        setOutputMappingName(name);
        setTemplate(data.tbTemplate);
        setOutputMappingUuid(uuid);
      }
    }
    templateUuid && fetchData();
  }, [templateUuid, teamId, tbStore.outputMappingUuid]);

  return { outputMappingName, outputMappingUuid, template, setTemplate, setOutputMappingName };
};

export { useTemplateAndOutputMappingName };
