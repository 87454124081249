import { useEffect, useState } from "react";
import { fusionDataApi } from "api";
import { useTeamId } from "store/hooks/useTeamId";

const useSourceSnowflakeData = (workflowUuid) => {
  const teamId = useTeamId();

  const [sourceSnowflakeData, setSourceSnowflakeData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data, status } = await fusionDataApi.workflows.getSourceSnowflake(teamId, workflowUuid);

      const warehouses = data?.warehouses ?? [];
      const warehouse = warehouses[0] ?? { name: "", id: null, databases: [] };

      if (status === 200) {
        setSourceSnowflakeData(warehouse);
      }
    };

    fetchData();
  }, []);

  return sourceSnowflakeData;
};

export { useSourceSnowflakeData };
