const TEMPLATE_STRUCTURE = Object.freeze({
  STRUCTURED: "STRUCTURED",
  TEXT: "TEXT",
  PDF_STRUCTURED: "PDF_STRUCTURED",
  PDF_OCR: "PDF_OCR",
  TREE: "TREE",
});

const ADD_NEW_RULE = "addNewRulePanel";
const AI_ASSISTANT_RULE = "askOpenAi";

export { TEMPLATE_STRUCTURE, ADD_NEW_RULE, AI_ASSISTANT_RULE };
