import { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { modalInstance, WarningConfirmationModal, R, C6, ModalError, Heading, Text, Button } from "@fundrecs/ui-library";
import { workflowsStore, tbStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { useTemplateAndOutputMappingName } from "store/hooks/useTemplateAndOutputMappingName";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { SelectTemplateMethod } from "./SelectTemplateMethod";
import { CreateTemplate } from "./CreateTemplate";
import { TransformAndMap } from "./TransformAndMap";
import { MODAL_IDS, WORKFLOW_CONFIG_STAGE } from "../../../utils/workflows/enums";
import { WorkflowConfigurationContext } from "components/workflows/ConfigureWorkflow/ConfigureWorkflow";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";
import { HeaderButtons } from "../EditTemplate/Header/HeaderButtons";
import { TemplateSettings } from "../EditTemplate/Modals/TemplateSettings";
import { DeleteTemplateModal } from "../EditTemplate/Modals/DeleteTemplate";
import { TemplateHeader } from "../TemplateHeader/TemplateHeader";
import { appSingleton } from "TemplateBuilder/components/utils/AppSingleton";
import styles from "./CreateTemplateStage.module.scss";

const SelectComponent = ({ activeStep, setActiveStep, stepParams, template, setTemplate, workflow, importRuleIndex }) => {
  const { name } = activeStep;
  switch (name) {
    case "STEP 01":
      return <SelectTemplateMethod setActiveStep={setActiveStep} />;
    case "STEP 02":
      return (
        <CreateTemplate
          setActiveStep={setActiveStep}
          stepParams={stepParams}
          template={template}
          setTemplate={setTemplate}
          workflow={workflow}
          importRuleIndex={importRuleIndex}
        />
      );
    case "STEP 03":
      return <TransformAndMap setActiveStep={setActiveStep} activeStep={activeStep} stepParams={stepParams} />;
    default:
      return <SelectTemplateMethod setActiveStep={setActiveStep} />;
  }
};

const CreateTemplateStage = observer(({ modalId }) => {
  const { setWorkflowStage, fieldValues, workflow, importRuleIndex } = useContext(WorkflowConfigurationContext);
  //TODO Need to check if this is needed anymore.
  const { outputMappingUuid } = fieldValues;

  const closeModalAndResetFields = () => {
    modalInstance(MODAL_IDS.CONFIRM_CLOSE_CREATE_TEMPLATE).toggle();
  };

  const onComplete = (templateUuid) => {
    const templates = workflow.getTemplateImportRules(importRuleIndex);
    const template = {
      priority: templates.length + 1,
      type: "MATCH_ALL",
      templateUuid: templateUuid,
    };

    workflow.addTemplateImportRule(template, importRuleIndex);

    setWorkflowStage(WORKFLOW_CONFIG_STAGE.OVERVIEW);
  };

  const [activeStep, setActiveStep] = useState({ name: "STEP 01", index: 1 });
  const teamId = useTeamId();

  const { template, outputMappingName, setTemplate } = useTemplateAndOutputMappingName(tbStore.getTemplateUuid(), teamId);

  useEffect(() => {
    if (workflowsStore.getIsTemplateView()) {
      setActiveStep({ name: "STEP 03", index: 3 });
    }
  }, [workflowsStore.getIsTemplateView()]);

  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
    { name: "STEP 03", index: 3 },
  ];

  const checkIsStep3 = () => {
    const { index } = activeStep;
    return index === 3;
  };

  const reset = (value) => {
    if (value === "APPROVE") {
      appSingleton.onComplete(appSingleton.templateUuid);
    }
    let reset = new Promise((resolve, reject) => {
      resolve(setWorkflowStage(WORKFLOW_CONFIG_STAGE.OVERVIEW));
    });
    reset.then(() => {
      workflowsStore.setIsTemplateView(false);
      tbStore.templateBuilderDoneButtonDisabled(false);

      setActiveStep({ name: "STEP 01", index: 1 });
    });
  };
  return (
    <>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_CLOSE_CREATE_TEMPLATE}
        heading="Are you sure you want to quit?"
        text="All progress in this session will be lost"
        cancel="Cancel"
        confirm="Quit"
        onConfirm={() => {
          reset();
        }}
      />
      <TemplateSettings template={template} setTemplate={setTemplate} teamId={teamId} />
      <DeleteTemplateModal teamId={teamId} template={template} onConfirm={reset} />

      <div className={!checkIsStep3() && styles.headerWrapper}>
        {checkIsStep3() ? (
          <TemplateHeader
            classes="pb-20 pl-20 pr-20 pt-20"
            onCloseClick={closeModalAndResetFields}
            template={template}
            outputMappingName={outputMappingName}
            outputMappingUuid={outputMappingUuid}
            contentLeft={<StatusCell value={template.status} />}
            contentRight={<HeaderButtons teamId={teamId} template={template} onClose={reset} />}
          />
        ) : (
          <ModalHeader
            onCloseClick={closeModalAndResetFields}
            CREATE_NEW_LOOKUP_MODAL_ID={modalId}
            title={"New template"}
            contentLeft={workflowsStore.getIsTemplateView() ? <StatusCell value={template.status} /> : <StatusCell value="Draft" />}
            contentCenter={<Step steps={steps} activeStep={activeStep} />}
            classes={[" pl-3 pr-32", `${!checkIsStep3() && "mb-72"}`, `${checkIsStep3() ? "mb-16 pl-32" : "mb-20"}`].join(" ")}
          />
        )}

        <div id="TemplateBuilderApp">{tbStore.getErrorModal() && tbStore.mountErrorModal(<ErrorModal teamId={teamId} />)}</div>
        <SelectComponent
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          stepParams={{ outputMappingUuid, onComplete, outputMappingName }}
          setTemplate={setTemplate}
          template={template}
          workflow={workflow}
          importRuleIndex={importRuleIndex}
        />
      </div>
    </>
  );
});

const ErrorModal = ({ teamId }) => {
  return (
    <R props="pt-48 justify-content-center">
      <C6 props="text-center pt-32 pb-32 pl-48 pr-48">
        <ModalError />

        <div className="pt-16">
          <Heading variant="h4" align="center">
            Widget failed to load
          </Heading>
        </div>
        <div className="pt-16 pb-16">
          <Text variant="tertiary" size="sm" align="center" weight="regular" element="p">
            Please try again
          </Text>
        </div>
        <Button
          onClick={() => {
            tbStore.reloadTemplateBuilder({ teamId });
          }}
        >
          <Text size="sm" weight="medium">
            Reload
          </Text>
        </Button>
      </C6>
    </R>
  );
};

export { CreateTemplateStage };
