import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/Store";
import { Text, Panel, PanelBody, PanelHeader, PanelFooter, Button, modalInstance, C4, C8, IconRightArrow } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import styles from "./AddOutputMappingPanel.module.scss";
import { useTeamId } from "store/hooks/useTeamId";
import { Spinner } from "components/ag-grid/Spinner/Spinner";

const AddOutputMappingPanel = observer(({ newOutputMappingUuid, report, addOutputMappingUuid, sectionIndex, handleCloseInternally = true }) => {
  const { outputMappingsStore } = useStore();

  const [activeOutputMapping, setActiveOutputMapping] = useState(null);
  const [rowData, setRowData] = useState(null);
  const teamId = useTeamId();

  useEffect(() => {
    if (newOutputMappingUuid) {
      setActiveOutputMapping(getExistingOutputMapping());
    } else {
      const [defaultOutputMapping] = outputMappingsStore.getOutputMappings();
      setActiveOutputMapping(defaultOutputMapping);
    }
  }, [newOutputMappingUuid]);

  useEffect(() => {
    if (activeOutputMapping) {
      const fetch = async () => {
        const { data } = await outputMappingsStore.getOutputMappingColumnsById({ teamId: teamId, outputMappingId: activeOutputMapping?.uuid });

        if (data) {
          setRowData(data);
        }
      };
      fetch();
    }
  }, [activeOutputMapping]);

  const getExistingOutputMapping = () => {
    const [existingOutputMapping] = outputMappingsStore.getOutputMappings().filter((outputMapping) => {
      return outputMapping?.uuid === newOutputMappingUuid;
    });

    return existingOutputMapping;
  };

  const handleClose = () => {
    setActiveOutputMapping(newOutputMappingUuid ? getExistingOutputMapping() : null);
    modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)).hide();
  };

  const OutputMappingElement = ({ outputMapping, index }) => {
    const { name, uuid } = outputMapping;

    return (
      <div
        onClick={() => {
          setRowData(null);
          setActiveOutputMapping(outputMapping);
        }}
        className={[
          `${styles.cursorPointer}`,
          " d-flex pb-8 pt-8 pl-12 pr-12",
          `${[activeOutputMapping?.uuid].includes(uuid) && styles.activeText}`,
          `${[activeOutputMapping?.uuid].includes(uuid) && styles.activeBackground}`,
        ].join(" ")}
        key={index}
      >
        <div className="me-auto">
          <Text size="sm" weight="regular">
            {name}
          </Text>
        </div>
        <div>{[activeOutputMapping?.uuid].includes(uuid) && <IconRightArrow className={`${styles.checkIconSize}`} />}</div>
      </div>
    );
  };

  return (
    report !== undefined && (
      <Panel panelId={MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)} size="lg">
        <PanelBody>
          <PanelHeader
            description="Choose an output mapping to use for your output structure. Only approved output mappings are available for use in your output/template."
            header="Select output mapping"
            onClick={handleClose}
          />
          <div className={styles.outputMappingPreview}>
            <C4 props={styles.outputMappingList}>
              {outputMappingsStore.getOutputMappings().map((outputMapping, index) => {
                return (
                  <React.Fragment key={index}>
                    <OutputMappingElement outputMapping={outputMapping} index={index} />
                  </React.Fragment>
                );
              })}
            </C4>
            <C8>
              <div className={["pt-24 pl-24 pr-24 pb-24", `${styles.bottomBorder}`].join(" ")}>
                <Text variant="primary" size="md" weight="bold">
                  {activeOutputMapping?.name}
                </Text>
              </div>
              <div className="pt-24 pr-24 pl-24 pb-24">
                {[null, undefined].includes(rowData) && (
                  <div className={styles.centeredSpinner}>
                    <Spinner />
                  </div>
                )}
                {rowData && (
                  <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                      <Text variant="primary" size="sm" weight="medium">
                        {`Columns (${rowData?.length ?? "0"})`}
                      </Text>
                    </div>
                    <div className={styles.tableBody}>
                      {rowData?.map((row, index) => {
                        return (
                          <div className="d-flex" key={index}>
                            <div className={[styles.tableColIndex, ""].join(" ")}>{index + 1}</div>
                            <div className={[`${styles.tableRow}`, "w-100"].join(" ")}>{row.columnName}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </C8>
          </div>
        </PanelBody>
        <PanelFooter>
          <Button color="tertiary" onClick={handleClose}>
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            onClick={() => {
              addOutputMappingUuid(activeOutputMapping?.uuid);
              handleCloseInternally && modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)).hide();
            }}
            size="md"
            disabled={activeOutputMapping?.uuid === null}
          >
            <Text size="sm">Select output mapping</Text>
          </Button>
        </PanelFooter>
      </Panel>
    )
  );
});

export { AddOutputMappingPanel };
