import { makeAutoObservable } from "mobx";
import { fusionDataApi } from "../api";

const NO_URL_LOADED = "#";

class TenantConfigStore {
  fusionDataUrl = NO_URL_LOADED;
  fusionAdminUrl = NO_URL_LOADED;
  fusionRecsUrl = NO_URL_LOADED;
  fusionReportingUrl = NO_URL_LOADED;
  frontendBranding = undefined

  constructor() {
    makeAutoObservable(this);
  }

  setTenantConfig = (data) => {
    this.fusionDataUrl = data.fusionDataUrl;
    this.fusionAdminUrl = data.fusionAdminUrl;
    this.fusionRecsUrl = data.fusionRecsUrl;
    this.outputWriters = data.outputWriters;
    this.fusionReportingUrl = data.fusionReportingUrl;
    this.frontendBranding = data.fusionAdminUrl.toLowerCase().includes("cloudmargin")
      ? false
      : data.frontendBranding === undefined
      ? true
      : data.frontendBranding;  };

  getFusionDataUrl = () => {
    return this.fusionDataUrl;
  };

  getFusionAdminUrl = () => {
    return this.fusionAdminUrl;
  };

  getFusionRecsUrl = () => {
    return this.fusionRecsUrl;
  };

  getFusionReportingUrl = () => {
    return this.fusionReportingUrl;
  };

  getOutputWriters = () => {
    return this.outputWriters;
  };

  getFrontendBranding = () => {
    return this.frontendBranding;
  };

  requestTenantConfig = async () => {
    const { data, status } = await fusionDataApi.tenantConfig.getTenantConfig();
    if (status === 200) {
      this.setTenantConfig(data);
      return true;
    } else {
      return false;
    }
  };
}

export { TenantConfigStore };
