import { useEffect, useContext } from "react";

import { OUTPUT_DESTINATION_TYPES } from "utils/workflows/enums";
import { ArrayOptionDropdown } from "../../DropdownOptions/ArrayOptionDropdown";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";
import { ToolTip, IconInformation } from "@fundrecs/ui-library";

const OutputDestionationView = ({
  sectionIndex,
  emailData,
  sftpData,
  recData,
  lookupData,
  outputDestination,
  selectedExportEmail,
  setSelectedExportEmail,
  selectedExportSftpConnection,
  setSelectedExportSftpConnection,
  selectedSftpFolder,
  setSelectedSftpFolder,
  selectedLookupTable,
  setSelectedLookupTable,
  selectedRecType,
  setSelectedRecType,
  selectedRecSide,
  setSelectedRecSide,
  workflowDestination,
  setWorkflowDestination,
  destinationWorkflowsData,
  destinationSnowflakeData,
  setSnowflakeDatabase,
  snowflakeDatabase,
  setSnowflakeSchema,
  snowflakeSchema,
  setSnowflakeTable,
  snowflakeTable,
}) => {
  const { workflow } = useContext(WorkflowConfigurationContext);

  const {
    connectionUuid = "",
    exportMappingId = "",
    recTypeId = "",
    recSideTmoUuid = "",
    workflowUuid = "",
    type,
    databaseName,
    schemaName,
    tableName,
  } = outputDestination;
  useEffect(() => {
    if (sftpData.length > 0 && type === OUTPUT_DESTINATION_TYPES.SFTP && !Object.hasOwn(selectedExportSftpConnection, "connectionUuid")) {
      //Find our existing connection matching connectionUuids
      //sftpData comes from API, connectionUuid from our object within reports
      const existingConnection = sftpData.find((connection) => connection.connectionUuid === connectionUuid);

      //Find our existing connection index matching connectionUuids so we can set a default in the dropdown
      const existingConnectionIndex = sftpData.findIndex((connection) => connection.connectionUuid === connectionUuid);

      //Merge existing connection when we set the selected also check for undefined
      existingConnection === undefined
        ? setSelectedExportSftpConnection({ ...{ index: 0 }, ...sftpData[0] })
        : setSelectedExportSftpConnection({ ...{ index: existingConnectionIndex }, ...existingConnection });

      // setSelectedExportSftpConnection({ ...getSelectedSource(sftpData, connectionUuid) });
      setSelectedSftpFolder(getSelectedExportMapping(getSelectedSource(sftpData, connectionUuid), exportMappingId));
    }

    if (emailData.length > 0 && type === OUTPUT_DESTINATION_TYPES.EMAIL && !Object.hasOwn(selectedExportEmail, "connectionUuid")) {
      setSelectedExportEmail({ ...getSelectedSource(emailData, connectionUuid) });
    }

    if (lookupData.length > 0 && type === OUTPUT_DESTINATION_TYPES.LOOKUP && !Object.hasOwn(selectedLookupTable, "uuid")) {
      setSelectedLookupTable({ ...getSelectedLookupSource(lookupData, connectionUuid) });
    }

    if (recData.length > 0 && type === OUTPUT_DESTINATION_TYPES.RECONCILIATION && !Object.hasOwn(selectedRecType, "recTypeId")) {
      setSelectedRecType({ ...getSelectedRecSource(recData, recTypeId) });
      setSelectedRecSide({ ...getSelectedRecSide(getSelectedRecSource(recData, recTypeId), recSideTmoUuid) });
    }

    if (destinationWorkflowsData.length > 0 && type === OUTPUT_DESTINATION_TYPES.WORKFLOW) {
      setWorkflowDestination({ ...getSelectedWorkflow(destinationWorkflowsData, workflowUuid) });
    }

    if (destinationSnowflakeData.length > 0 && type === OUTPUT_DESTINATION_TYPES.SNOWFLAKE) {
      setSnowflakeDatabase({ ...getSelectedSnowflakeDatabase(destinationSnowflakeData, databaseName) });
      setSnowflakeSchema({ ...getSelectedSnowflakeSchema(getSelectedSnowflakeDatabase(destinationSnowflakeData, databaseName), schemaName) });
      setSnowflakeTable({
        ...getSelectedSnowflakeTable(getSelectedSnowflakeSchema(getSelectedSnowflakeDatabase(destinationSnowflakeData, databaseName), schemaName), tableName),
      });
    }
  }, [sftpData, emailData, lookupData, recData, type, destinationWorkflowsData, destinationSnowflakeData]);

  const updateExportSftpConnection = (index, value) => {
    setSelectedExportSftpConnection({ ...{ index }, ...value });
    setSelectedSftpFolder(getSelectedExportMapping({ ...{ index }, ...value }, exportMappingId));
  };

  const updateExportEmailAddress = (index, value) => {
    setSelectedExportEmail({ ...{ index }, ...value });
  };

  const updateLookupTable = (index, value) => {
    const { outputMappingUuid } = value;
    workflow.updateCurrentWorkflowReportOutputMapping(sectionIndex, outputMappingUuid);
    setSelectedLookupTable({ ...{ index }, ...value });
  };

  const updateSftpFolder = (index, value) => {
    setSelectedSftpFolder({ ...{ index }, ...value });
  };

  const updateReconciliationType = (index, value) => {
    setSelectedRecType({ ...{ index }, ...value });
  };

  const updateReconciliationSide = (index, value) => {
    setSelectedRecSide({ ...{ index }, ...value });
  };

  const updateWorkflowDestination = (index, value) => {
    setWorkflowDestination({ ...{ index }, ...value });
  };

  const updateSnowflakeDatabase = (index, value) => {
    setSnowflakeDatabase({ index, ...value });
    setSnowflakeSchema(getSelectedSnowflakeSchema({ index, ...value }, schemaName));
    setSnowflakeTable(getSelectedSnowflakeTable(getSelectedSnowflakeSchema({ index, ...value }, schemaName), tableName));
  };

  const updateSnowflakeSchema = (index, value) => {
    setSnowflakeSchema({ index, ...value });
    setSnowflakeTable(getSelectedSnowflakeTable({ index, ...value }, tableName));
  };

  const updateSnowflakeTable = (index, value) => {
    setSnowflakeTable({ index, ...value });
  };

  /**
   * This will assemble the selected object for email/sftp if a source exists already.
   * If none exist it will set a default.
   *
   * This method returns an object.
   *
   * @param data is an array of objects
   */
  const getSelectedSource = (data, connectionUuid) => {
    /**
     * Find matching connection and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const sourceConnection = data.find((connection) => connection.connectionUuid === connectionUuid);
    const sourceConnectionIndex = data.findIndex((connection) => connection.connectionUuid === connectionUuid);
    /**
     * Check if source connection is undefined.
     * if it is set the default. We should have a default if we are at this point because we checked for sftpData length.
     * Otherwise if it's not undefined then construct the existing connection.
     * **/
    const selectedConnection = sourceConnection === undefined ? { index: 0, ...data[0] } : { index: sourceConnectionIndex, ...sourceConnection };
    return selectedConnection;
  };

  /**
   * Lookup is a little different as it's not strictly based on a connection defined in Admin
   * We use the selected table UUID as the connectionUuid to reuse the existing models and logic as much as possible
   */
  const getSelectedLookupSource = (data, uuid) => {
    /**
     *
     */
    const sourceConnection = data.find((connection) => connection.uuid === uuid);
    const sourceConnectionIndex = data.findIndex((connection) => connection.uuid === uuid);
    /**
     * Check if source connection is undefined.
     * if it is set the default. We should have a default if we are at this point because we checked for length.
     * Otherwise if it's not undefined then construct the existing connection.
     * **/
    const selectedConnection = sourceConnection === undefined ? { index: 0, ...data[0] } : { index: sourceConnectionIndex, ...sourceConnection };
    return selectedConnection;
  };

  const getSelectedExportMapping = (selectedExportSftpConnection, exportMappingId) => {
    const exportMapping = selectedExportSftpConnection.exportMappings.find((mapping) => mapping.id === exportMappingId);
    const exportMappingIndex = selectedExportSftpConnection.exportMappings.findIndex((mapping) => mapping.id === exportMappingId);

    //Set defaultInputMapping as selected if the inputMappings array has any objects otherwise set empty object
    const defaultExportMapping = selectedExportSftpConnection.exportMappings.length > 0 ? selectedExportSftpConnection.exportMappings[0] : {};
    const selectedExportMapping = exportMapping === undefined ? defaultExportMapping : { index: exportMappingIndex, ...exportMapping };
    return selectedExportMapping;
  };

  /**
   * Reconciliation is a little different as it's not strictly based on a connection defined in Admin
   * We use the selected rec UUID as the connectionUuid to reuse the existing models and logic as much as possible
   */
  const getSelectedRecSource = (data, recTypeId) => {
    const sourceConnection = data.find((connection) => connection.recTypeId === recTypeId);
    const sourceConnectionIndex = data.findIndex((connection) => connection.recTypeId === recTypeId);

    const selectedConnection = sourceConnection === undefined ? { index: 0, ...data[0] } : { index: sourceConnectionIndex, ...sourceConnection };
    return selectedConnection;
  };

  const getSelectedRecSide = (selectedRecType, recSideTmoUuid) => {
    const recSide = selectedRecType.tmoList.find((tmo) => tmo.tmoUuid === recSideTmoUuid);
    const recSideIndex = selectedRecType.tmoList.findIndex((tmo) => tmo.tmoUuid === recSideTmoUuid);

    const defaultRecSide = selectedRecType.tmoList.length > 0 ? selectedRecType.tmoList[0] : {};
    const selectedRecSide = recSide === undefined ? defaultRecSide : { index: recSideIndex, ...recSide };
    return selectedRecSide;
  };

  const getSelectedWorkflow = (data, workflowUuid) => {
    /**
     * Find matching workflow and index so we can set this as selected in dropdown.
     * If we have no matches we will get an undefined.
     */
    const workflowDestination = data.find((workflow) => workflow.uuid === workflowUuid);
    const workflowDestinationIndex = data.findIndex((workflow) => workflow.uuid === workflowUuid);
    /**
     * Check if workflow is undefined if it is set the default for now.
     * **/
    const selectedWorkflow = workflowDestination === undefined ? { index: 0, ...data[0] } : { index: workflowDestinationIndex, ...workflowDestination };
    return selectedWorkflow;
  };

  const getSelectedSnowflakeDatabase = (data, databaseName) => {
    const snowflakeDatabase = data.find((database) => database.name === databaseName);
    const snowflakeDatabaseIndex = data.findIndex((database) => database.name === databaseName);

    const selectedSnowflakeDatabase = snowflakeDatabase === undefined ? { index: 0, ...data[0] } : { index: snowflakeDatabaseIndex, ...snowflakeDatabase };

    return selectedSnowflakeDatabase;
  };

  const getSelectedSnowflakeSchema = (data, schemaName) => {
    const { schemas } = data;

    const snowflakeSchema = schemas.find((schema) => schema.name === schemaName);
    const snowflakeSchemaIndex = schemas.findIndex((schema) => schema.name === schemaName);

    const selectedSnowflakeSchema = snowflakeSchema === undefined ? { index: 0, ...schemas[0] } : { index: snowflakeSchemaIndex, ...snowflakeSchema };

    return selectedSnowflakeSchema;
  };

  const getSelectedSnowflakeTable = (data, tableName) => {
    const { tables } = data;

    const snowflakeTable = tables.find((table) => table.name === tableName);
    const snowflakeTableIndex = tables.findIndex((table) => table.name === tableName);

    const selectedSnowflakeTable = snowflakeTable === undefined ? { index: 0, ...tables[0] } : { index: snowflakeTableIndex, ...snowflakeTable };

    return selectedSnowflakeTable;
  };

  return (
    <>
      {OUTPUT_DESTINATION_TYPES.WORKFLOW === type && (
        <ArrayOptionDropdown
          label="Choose workflow"
          options={destinationWorkflowsData}
          option={workflowDestination}
          setOption={updateWorkflowDestination}
          objKey="name"
        />
      )}
      {OUTPUT_DESTINATION_TYPES.SFTP === type && (
        <>
          <ArrayOptionDropdown
            label="Choose SFTP connection"
            options={sftpData}
            option={selectedExportSftpConnection}
            setOption={updateExportSftpConnection}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose SFTP directory"
            options={selectedExportSftpConnection.exportMappings}
            disabled={selectedExportSftpConnection.exportMappings == null || selectedExportSftpConnection.exportMappings.length === 0}
            option={selectedSftpFolder}
            setOption={updateSftpFolder}
            objKey="name"
            objKey2="fileSource"
          />
        </>
      )}

      {OUTPUT_DESTINATION_TYPES.SNOWFLAKE === type && (
        <>
          <ArrayOptionDropdown
            label="Choose database"
            options={destinationSnowflakeData}
            option={snowflakeDatabase}
            setOption={updateSnowflakeDatabase}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose schema"
            options={snowflakeDatabase?.schemas}
            disabled={snowflakeDatabase?.schemas?.length === 0}
            option={snowflakeSchema}
            setOption={updateSnowflakeSchema}
            objKey="name"
          />
          <ArrayOptionDropdown
            label="Choose table"
            options={snowflakeSchema?.tables}
            disabled={snowflakeSchema?.tables?.length === 0}
            option={snowflakeTable}
            setOption={updateSnowflakeTable}
            objKey="name"
          />
        </>
      )}

      {OUTPUT_DESTINATION_TYPES.LOOKUP === type && (
        <>
          <ArrayOptionDropdown label="Choose lookup table" options={lookupData} option={selectedLookupTable} setOption={updateLookupTable} objKey="name" />
        </>
      )}

      {OUTPUT_DESTINATION_TYPES.EMAIL === type && (
        <>
          <ArrayOptionDropdown
            label="Email address"
            options={emailData}
            option={selectedExportEmail}
            setOption={updateExportEmailAddress}
            objKey="emailAddress"
          />
        </>
      )}

      {OUTPUT_DESTINATION_TYPES.RECONCILIATION === type && (
        <>
          <ArrayOptionDropdown
            label="Choose reconciliation type"
            options={recData}
            option={selectedRecType}
            setOption={updateReconciliationType}
            objKey="recTypeName"
            icon={
              <span className="ml-4">
                <ToolTip
                  text="Don't see the reconciliation type you're looking for? Go to Reconciliations to create one before configuring your internal and external workflows"
                  direction="top"
                >
                  <IconInformation className="light-text-muted icon-size" />
                </ToolTip>
              </span>
            }
          />
          <ArrayOptionDropdown
            label="Choose side"
            options={selectedRecType.tmoList}
            option={selectedRecSide}
            setOption={updateReconciliationSide}
            objKey="tmoName"
          />
        </>
      )}
    </>
  );
};

export { OutputDestionationView };
