/**
 * Created by Naman on 10/12/2016.
 */
import CommandResult from "./domain/CommandResult";
class AppSingleton {
  constructor(props) {
    this.moduleName = "TB";
    this.state = "New";
    //Default approval options necessary to allow render before returned data from FR
    this.approvalOptions = {
      approve: { show: false, enable: false, message: "" },
      changeType: { show: true, enable: false, message: "" },
      delete: { show: true, enable: false, message: "" },
      reject: { show: false, enable: false, message: "" },
      submit: { show: true, enable: false, message: "" },
    };
    this.templateTypes = {};
    this.generalCommands = [];
    this.step = undefined;
    this.templateUuid = undefined;
    this.teamId = undefined;
    this.commandResultArray = undefined;
  }
}
export const appSingleton = new AppSingleton();

//state= New,structured
