import React from "react";
import FundsDataView from "./structured/FundsDataView";
import TextDataView from "./unstructured/TextDataView";
import { TEMPLATE_STRUCTURE } from "../utils/enums";
import { appSingleton } from "../utils/AppSingleton";

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.commandResultArray.length == 0) {
      return <div className="data-view"></div>;
    }
    let view = this.props.commandResultArray.map((commandResult, index) => {
      if (commandResult.structure === TEMPLATE_STRUCTURE.STRUCTURED) {
        return <FundsDataView key={index} commandResult={commandResult} parentId="DataTable" templateColumns={this.props.templateColumns} />;
      } else if (
        commandResult.structure === TEMPLATE_STRUCTURE.TEXT ||
        commandResult.structure === TEMPLATE_STRUCTURE.PDF_STRUCTURED ||
        commandResult.structure === TEMPLATE_STRUCTURE.PDF_OCR ||
        commandResult.structure === TEMPLATE_STRUCTURE.TREE
      ) {
        return <TextDataView key={index} commandResult={commandResult}></TextDataView>;
      }
    });
    return (
      <div id="#dataTable" className="data-view">
        {view}
      </div>
    );
  }

  //Prevent Data table being redrawn , unless TBapp.updateState is called
  shouldComponentUpdate(nextProps, nextState) {
    if (appSingleton.dataUpdated) {
      appSingleton.dataUpdated = false;
      return true;
    } else {
      return false;
    }
  }
}
