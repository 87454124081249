import { useEffect, useState } from "react";
import { IconCloseRemove, Heading, OneProductOutputMappingDataIcon, Text, IconPaperClip, modalInstance } from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { useStore } from "store/Store";
import HttpMediator from "TemplateBuilder/components/utils/http/HttpMediator";
import { appSingleton } from "TemplateBuilder/components/utils/AppSingleton";
import { STATUS_VALUES, MODAL_IDS } from "utils/templates/enums";
import { TEMPLATE_STRUCTURE } from "TemplateBuilder/components/utils/enums";
import { AddOutputMappingPanel } from "components/workflows/ConfigureWorkflow/stages/output/panels/AddOutputMappingPanel";
import { ChangeSampleFile } from "../ChangeSampleFile/ChangeSampleFile";
import { tbAppChannel } from "TemplateBuilder/components/utils/communication/AppChannels";
import styles from "./TemplateHeader.module.scss";

const TemplateHeader = observer(({ onCloseClick, template, outputMappingName, outputMappingUuid, contentLeft, contentRight, classes }) => {
  const { tbStore, outputMappingsStore } = useStore();
  const { status = STATUS_VALUES?.DRAFT, name = "" } = template || {};

  const getFundsData = (data) => ({
    columns: data?.fundsData?.headers || [],
    rows: data?.fundsData?.rows || [],
    structure: data?.structure || {},
  });

  const [headerData, setHeaderData] = useState({ columns: [], rows: [], structure: {} });

  useEffect(() => {
    // Initialize headerData on the first load
    setHeaderData(getFundsData(appSingleton?.commandResultArray));

    const refreshHandler = () => {
      setHeaderData(getFundsData(appSingleton?.commandResultArray));
    };

    // Subscribe to the event
    const subscription = tbAppChannel.subscribe("dataRefresh", refreshHandler);

    // Cleanup: Unsubscribe when component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, [appSingleton?.commandResultArray]);

  useEffect(() => {
    return () => {
      tbStore.templateSampleFile = [];
      appSingleton.commandResultArray = undefined;
    };
  }, []);

  const changeTemplateOutputMapping = async (outputMappingUuid) => {
    const { success } = await HttpMediator.updateTemplateType(outputMappingUuid);
    if (success) {
      modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING).hide();
    }
  };

  const { columns, rows, structure } = headerData;

  const disabledOutputMappingAndSampleFileText = [STATUS_VALUES.APPROVED, STATUS_VALUES.PUBLISHED, STATUS_VALUES.PENDING, STATUS_VALUES.SUBMITTED].includes(
    status
  );
  return (
    <>
      <div className={["d-flex gap-0", `${classes}`, `${styles.headerBorder}`].join(" ")}>
        <div className="d-flex flex-column">
          <div className="d-flex p-0 m-0">
            <IconCloseRemove
              className={["btn-lg-svg", styles.iconCloseRemove].join(" ")}
              onClick={() => {
                onCloseClick();
              }}
            />
            <div className={styles.verticalDividerSpan} />
            <Heading variant="h5" classes="pr-20 mb-0">
              {name}
            </Heading>
            {contentLeft}
          </div>
          <div className={styles.contentAreaBottomLeft}>
            <div className={styles.templateOutputMappingIcon}>
              <OneProductOutputMappingDataIcon className="btn-xs-svg" />
            </div>
            <Text variant="tertiary" size="xs" element="div" weight="regular" classes={styles.templateOutputMappingText}>
              Output Mapping:
            </Text>
            <Text
              variant={disabledOutputMappingAndSampleFileText ? "tertiary" : "link"}
              size="xs"
              element="div"
              weight="regular"
              classes={[styles.templateHeaderLink, !disabledOutputMappingAndSampleFileText && styles.cursorPointer].join(" ")}
              onClick={() => {
                !disabledOutputMappingAndSampleFileText && modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING).show();
              }}
            >
              {outputMappingName}
            </Text>
            <div className={styles.templateOutputMappingIcon}>
              <IconPaperClip className="btn-xs-svg" />
            </div>
            <Text variant="tertiary" size="xs" element="div" weight="regular" classes={styles.templateOutputMappingText}>
              Sample:
            </Text>
            <Text
              variant={disabledOutputMappingAndSampleFileText ? "tertiary" : "link"}
              size="xs"
              element="div"
              weight="regular"
              classes={[styles.templateHeaderLink, !disabledOutputMappingAndSampleFileText && styles.cursorPointer].join(" ")}
              onClick={() => {
                !disabledOutputMappingAndSampleFileText && modalInstance(MODAL_IDS.CHANGE_SAMPLE_FILE).show();
              }}
            >
              {tbStore.templateSampleFile.length > 0 && (
                <>
                  {`${tbStore.templateSampleFile[0]}${
                    structure === TEMPLATE_STRUCTURE.STRUCTURED
                      ? ` (${rows.length} ${rows.length === 1 ? "row, " : "rows, "} ${columns.length} ${columns.length === 1 ? "column" : "columns"})`
                      : ""
                  }`}
                </>
              )}
            </Text>
          </div>
        </div>
        <div className="ms-auto mt-auto">
          <div className={styles.contentAreaRight}>{contentRight}</div>
        </div>
      </div>

      {outputMappingsStore.getOutputMappings().length > 0 && (
        <AddOutputMappingPanel
          report={true}
          sectionIndex=""
          handleCloseInternally={false}
          addOutputMappingUuid={(value) => {
            changeTemplateOutputMapping(value);
          }}
          newOutputMappingUuid={outputMappingUuid}
        />
      )}

      {tbStore.templateSampleFile.length > 0 && <ChangeSampleFile />}
    </>
  );
});

export { TemplateHeader };
